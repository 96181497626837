<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <img
      src="../../../../assets/images/SifiLogo.svg"
      alt="Sifi Logo"
      title="Sifi logo"
      class="sifi-logo"
    />
    <!-- <a class="logo" href="#" (click)="navigateHome()">SIFI-<span>NETWORKS</span></a> -->
  </div>
  <!-- <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select> -->
</div>
<div class="header-container">
  <nb-actions size="small">
    <nb-action>
      <!-- <button nbButton outline status="primary" printSectionId="print-section" ngxPrint size="tiny">PDF</button>&nbsp;
      <button nbButton outline status="danger" printSectionId="print-section" ngxPrint size="tiny">CSV</button>
      <nb-select
        placeholder="Reports"
        class="d-flex"
        status="primary"
        size="small"
        shape="semi-round"
        fullWidth="true"
        (selectedChange)="download('pdf')"
      >
        <nb-option value="'pdf'">PDF</nb-option>
        <nb-option value="'csv'" >CSV</nb-option>-->
      <!-- </nb-select> -->
    </nb-action> 
    <!-- <nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action>
    <nb-action class="control-item" icon="email-outline"></nb-action>
    <nb-action class="control-item" icon="bell-outline"></nb-action> -->
    <nb-action class="user-action" *nbIsGranted="['view', 'user']">
      <nb-user
        [nbContextMenu]="userMenu"
        [onlyPicture]="userPictureOnly"
        [name]="user?.name"
        [title]="user?.title"
        [picture]="user?.picture"
        badgeStatus="success"
        badgePosition="bottom right"
        color="#cccccc"
      >
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
