import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class DataService {
  constructor(private _http: HttpClient) {}
  public  urls = environment.base_Url;

  post(url: string, params: any) {
    return this._http.post(this.urls+url, params);
  }

  get(url: string) {
    return this._http.get(this.urls+url);
  }

  put(url: string, params: any) {
    return this._http.put(this.urls+url, params);
  }


  geParam(url: string, param?: any) {
    return this._http.get(this.urls + url+param);
  }
}
