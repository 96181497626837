import { Injectable } from "@angular/core";
import { map, takeUntil } from "rxjs/operators";
import { DataService } from "../../services/data.service";
import { Login } from "../../models/login.model";
import { environment } from "../../../environments/environment";
import { ILoginResponse } from "../../models/loginResponse.model";
import { forkJoin, Subject } from "rxjs";
import { Projects, Stage, Status, Vendors } from "../../models/constants";
@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(private _dataService: DataService) {}
  private _user: any;
  private _unsubscribe = new Subject();

  /*login service request*/
  public logon(loginObject: Login) {
    const url = `${environment.base_Url + "Account/authenticate"}`;
    return this._dataService.post("Account/authenticate", loginObject).pipe(
      map((user: ILoginResponse) => {
        sessionStorage.setItem("token", user?.data?.jwToken);
        sessionStorage.setItem("user", JSON.stringify(user));
        this.setUser(user);
        return user as ILoginResponse;
      })
    );
  }

  public setUser(user: any) {
    this._user = user;
  }

  public getUser() {
    return this._user;
  }
}
