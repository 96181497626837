import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthService } from "./services/auth.service";
import { tap } from "rxjs/operators";
import { Router } from "@angular/router";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private _authService: AuthService,private _router:Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // console.log("in interceptor", request);
    /*
    Setting JWT Token in headers of request.
   */
    const isLoggedIn = sessionStorage.getItem("token") ? true : false;
    if (isLoggedIn) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
    }

    return next.handle(request).pipe(tap(()=>{},(err:any)=>{
      if(err instanceof HttpErrorResponse){
        if(err.status != 401){
          return;
        }
        else{
          this._router.navigateByUrl('/home')
        }
      }
    }))
  }

}
